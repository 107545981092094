import React from "react";
import Button from "../../components/button/Button";
import "./About.css";

const About = () => {
  const openCV = () => {
    const cvUrl = "https://drive.google.com/file/d/14SO0jj0lzqjMpkGxSlxGflLJyRt18heV/view?usp=sharing";
    window.open(cvUrl, "_blank");
  };

  return (
    <div className="aboutWrapper">
      <div className="aboutContainer">
        <h3>About me</h3>
        <p>
        Passionate about web development with a keen interest in UX/UI design, I bring a strong foundation in problem-solving and attention to detail from my previous roles. I am eager to contribute my skills and enthusiasm to a dynamic team, delivering impactful digital solutions.
        </p>
        <Button
          children="My CV"
          onClick={openCV}
        />
      </div>
    </div>
  );
};

export default About;

